import { Link } from 'react-router-dom';
import './Footer.css';
import React from 'react';

const Footer = () => {
    return (
        <>
            <div className='FooterContainer'>
                <div>
                    <p className='Heading'>CONTACT INFO</p>
                    <div>
                        <a href="https://api.whatsapp.com/send?phone=+916291307553&text=Hello, I would like to connect with you regarding Astronumerals!">
                            <i className="fa-brands fa-whatsapp fa-lg"></i>
                        </a>
                        <p>+91 62913 07553</p>
                    </div>
                    <div>
                        <a href="mailto:priasonawat@gmail.com">
                            <i className="fa-regular fa-envelope fa-lg"></i>
                        </a>
                        <p>priasonawat@gmail.com</p>
                    </div>
                    <div>
                        <i className="fa-solid fa-location-dot fa-lg "></i>
                        <p>Kolkata, India</p>
                    </div>
                </div>
                <div className='Links'>
                    <p className='Heading'>QUICK LINKS</p>
                    <Link className="quicklinks" to="/">Home</Link>
                    <Link className="quicklinks" to="/services">Services</Link>
                    <Link className="quicklinks" to="/getintouch">Contact Us</Link>
                    <Link className="quicklinks" to="/privacypolicy">Privacy Policy</Link>
                </div>
            </div>
            <div className='CopyrightContainer'>
                <p>Copyright 2024 All rights reserved</p>
            </div>
        </>
    )
}

export default Footer