import React, { useState } from 'react'
import './Form.css'
const Form = () => {
    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(inputs);
        var url = "https://wa.me/916291307553?text="
            + "Name: " + inputs.username + "%0a"
            + "Phone: " + inputs.contact + "%0a"
            + "Email: " + inputs.email + "%0a"
            + "Date of birth: " + inputs.dob + "%0a"
            + "Message: " + inputs.message;

        window.open(url, '_blank').focus();
    }
    return (
        <form className="Form" onSubmit={handleSubmit}>
            <div className='FormFields'>
                <div className="Column1">
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="username">NAME</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="text"
                                name="username"
                                value={inputs.username || ""}
                                placeholder="Your name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="contact">CONTACT NUMBER</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="tel"
                                name="contact"
                                value={inputs.contact || ""}
                                placeholder="Your contact number"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="email">EMAIL</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="email"
                                name="email"
                                value={inputs.email || ""}
                                onChange={handleChange}
                                placeholder="Your email address"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="dob">DATE OF BIRTH</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="date"
                                name="dob"
                                placeholder='Your D.O.B.'
                                value={inputs.dob || ""}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="Column2 row">
                    <div className="col-25">
                        <label htmlFor="message">MESSAGE</label>
                    </div>
                    <div className="textAreaDiv">
                        <textarea
                            cols='60' rows='8' wrap="hard"
                            name="message"
                            value={inputs.message || ""}
                            onChange={handleChange}
                            placeholder='Your message'
                            required></textarea>
                    </div>
                </div>
            </div>
            <div className='ButtonDiv'>
                <input className="Button" type="submit" value="SEND" />
            </div>
        </form>
    )
}

export default Form