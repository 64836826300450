import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails.jsx";
import GetInTouch from "./pages/GetInTouch/GetInTouch";
import Policy from "./pages/Policy/Policy";
import Offers from "./pages/Offers/Offers";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:id" element={<ServicesDetails />} />
        <Route path="/getintouch" element={<GetInTouch />} />
        <Route path="/privacypolicy" element={<Policy />} />
        <Route path="/offers" element={<Offers />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
