import Button from "../../layouts/Button/Button";
import Form from "../../layouts/Form/Form";
import Heading from "../../layouts/Heading/Heading";
import "./Offers.css";
import React, { useLayoutEffect } from "react";
import logo from "../../images/logo.png";
const Offers = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const currency = "INR";
  const receiptId = "567";
  const paymentHandler = async (e,amount) => {
    const response = await fetch("https://astronumerals-backend.onrender.com/order", {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();
    console.log(order);

    var options = {
      key: "rzp_test_I9gik1cai4PF3s", // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency,
      name: "Astronumerals", //your business name
      description: "Test Transaction",
      image: logo,
      order_id: order.id, //Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          ...response,
        };

        const validateRes = await fetch(
          "https://astronumerals-backend.onrender.com/order/validate",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonRes = await validateRes.json();
        console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: "Astronumeral Client", //your customer's name
        email: "NA",
        contact: "NA", //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };

  return (
    <>
      <div className="offerHeader">
        Unlock the Secrets of Your Life with Our Online Numerology Consultation
      </div>
      <p className="offerText1">
        Discover the hidden patterns and insights of your life through the power
        of numbers.
      </p>
      <div className="btnPaymentContainer">
      <button className="buttonPayment" onClick={(e)=>paymentHandler(e,109900)}>
        <p>Book Your 20-Minute Session Now at <s>₹1999/-</s> ₹1099/-</p>
      </button>
      </div>
      <div className="btnPaymentContainer">
      <button className="buttonPayment" onClick={(e)=>paymentHandler(e,39900)}>
      <p>Book Full Readings and Predictions at <s>₹999/-</s> ₹399/-</p>
      </button>
      </div>
      <div className="offerTextImgCon">
        <div className="offerText2">
          <h1>Discover a Range of Services to Enhance Your Life</h1>
          <p>
            Unlock deeper insights and guidance with our array of personalized
            services. Whether you're seeking clarity in your career, love life,
            health, or personal growth, we offer comprehensive solutions
            tailored to your needs.
          </p>
          <Button url="/services" title="Our Services" cName="ButtonDiv" />
        </div>
        <div className="circle4">
          <img
            src={require(`../../images/offerImages/offer_1.png`)}
            alt="offer"
          ></img>
        </div>
      </div>
      <div className="offerText2">
        <h1>What You Will Receive</h1>
      </div>
      <div className="allSubSectionOffer">
        <div className="allSubSection_CardOffer">
          <div>
          <h1>01</h1>
          <img
            src={require(`../../images/offerImages/offer_2.png`)}
            alt="offerImage"
          ></img>

          
          </div>
          <p>Comprehensive</p>
          <p>Analysis</p>
        </div>
        <div className="allSubSection_CardOffer">
          <div>
          <h1>02</h1>
          <img
            src={require(`../../images/offerImages/offer_3.png`)}
            alt="offerImage"
          ></img>
         
          </div>
          <p>Personalized</p><p> Insights</p>
        </div>
        <div className="allSubSection_CardOffer">
          <div>
          <h1>03</h1>
          <img
            src={require(`../../images/offerImages/offer_4.png`)}
            alt="offerImage"
          ></img>
          
          </div>
          <p>Customized</p><p>Remedies</p>
        </div>
      </div>
      <div className="offerText2">
        <h1>How It Works?</h1>
      </div>
      <div className="flowContainer">
        <div className="flowCircle">
        <h2 className="flowHeader">Book your
        Session</h2>
        </div>
        <div className="flowImages">
          <img className="flowLine1"src={require(`../../images/offerImages/arrow_line.png`)}></img>
          <img className="flowArrow"src={require(`../../images/offerImages/arrow.png`)}></img>
          <img className="flowLine2"src={require(`../../images/offerImages/arrow_line.png`)}></img>
        </div>
        <div className="flowCircle">
        <h2 className="flowHeader">Provide
        Details</h2>
        </div>
        <div className="flowImages">
          <img className="flowLine1"src={require(`../../images/offerImages/arrow_line.png`)}></img>
          <img className="flowArrow"src={require(`../../images/offerImages/arrow.png`)}></img>
          <img className="flowLine2"src={require(`../../images/offerImages/arrow_line.png`)}></img>
        </div>
        <div className="flowCircle">
        <h2 className="flowHeader">Consultation</h2>
        </div>
        <div className="flowImages">
          <img className="flowLine1"src={require(`../../images/offerImages/arrow_line.png`)}></img>
          <img className="flowArrow"src={require(`../../images/offerImages/arrow.png`)}></img>
          <img className="flowLine2"src={require(`../../images/offerImages/arrow_line.png`)}></img>
        </div>
        <div className="flowCircle">
        <h2 className="flowHeader">Follow Up</h2>
        </div>
      </div>
      <div className="formHeader">
        Book Your Consultation Today and Unlock the Power of Numerology!
      </div>
      <Form />
      <div className="getAnswersOffers">
        <h2>Get Answers to Your Questions on:</h2>
        <ul>
          <li>Name and Date of Birth Analysis</li>
          <li>Numerology Name Analysis</li>
          <li>Lo Shu Grid Creation</li>
          <li>Missing Number Remedies</li>
          <li>Name Correction</li>
          <li>Main Planet Number</li>
        </ul>
        <ul>
          <li>Life Path Number</li>
          <li>Compound Destiny Number</li>
          <li>Destiny/Name Number</li>
          <li>Personalized Remedies</li>
          <li>Signature Correction</li>
          <li>Lucky Numbers and Colors</li>
        </ul>
      </div>

      <h1 className="queryHeader">Got Queries? We’ve got your answers.</h1>
      <div className="queryContainer queryOffer">
        <div className="queryCard">
          <h5>What is the technique used for the analysis?</h5>
          <p>
            We use a combination of astrological knowledge and numerological
            aspects based on birth dates.
          </p>
        </div>
        <div className="queryCard">
          <h5>Is the process confidential?</h5>
          <p>
            Absolutely! Your details are completely safe and the analysis is
            done maintaining strict confidentiality.
          </p>
        </div>
      </div>
      <div className="queryContainer queryOffer">
        <div className="queryCard">
          <h5>How to book a session?</h5>
          <p>
            Simply click on the “Get In Touch” button on our page. You’ll be
            directed to fill up a form. Our team will get back to you post
            submission.
          </p>
        </div>

        {/* <div className="queryCard">
     <h5>How to book a session?</h5>
     <p>
     Simply click on the “Get In Touch” button on our page. You’ll be directed to fill up a form. Our team will get back to you post submission.
     </p>
   </div> */}
      </div>
    </>
  );
};

export default Offers;
